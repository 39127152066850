import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LandingComponent } from './components/login-components/landing/landing.component';
import { UserSettingsPageComponent } from './pages/user-settings-page/user-settings-page/user-settings-page.component';
import { UsersPageComponent } from './pages/users-page/users-page.component';
import { CompareArticlesComponent } from './components/compare-articles-components/compare-articles.component';
import { IsDealerGuard } from './guards/is-dealer.guard';
import { ArticleDetailsComponent } from './components/article-components/article-details/article-details.component';
import { BasketsPageComponent } from './pages/baskets-page/baskets-page.component';
import { BasketPageComponent } from './pages/basket-page/basket-page.component';
import { PreorderBasketPageComponent } from './pages/preorder-basket-page/preorder-basket-page.component';
import { CheckoutPageComponent } from './pages/checkout-page/checkout-page.component';
import { SentBasketOverviewPageComponent } from './pages/sent-basket-overview-page/sent-basket-overview-page.component';
import { ImageBankArticleDetailsComponent } from './components/image-bank-components/image-bank-article-details/image-bank-article-details.component';
import { BasketPaymentConditionsComponent } from './components/basket-components/basket-payment-conditions/basket-payment-conditions.component';
import { FinancialPageComponent } from './pages/financial-page/financial-page.component';
import { NewsPageComponent } from './pages/news-page/news-page.component';
import { HelpdeskPageComponent } from './pages/helpdesk-page/helpdesk-page.component';
import { ProfilesPageComponent } from './pages/profiles-page/profiles-page.component';
import { CompanyPageComponent } from './pages/company-page/company-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { NotImplementedPageComponent } from './pages/not-implemented-page/not-implemented-page.component';
import { TurnoverStatisticsPageComponent } from './pages/turnover-statistics-page/turnover-statistics-page.component';
import { WarrantyPageComponent } from './pages/warranty-page/warranty-page.component';
import { YearAgreementComponent } from './pages/year-agreement/year-agreement.component';
import { LotteryRegistrationPageComponent } from './pages/lottery-registration-page/lottery-registration-page.component';
import { SlideshowsOverviewPageComponent } from './pages/slideshows-overview-page/slideshows-overview-page.component';
import { LeaseModelsComponent } from './components/general-components/lease-models/lease-models.component';
import { OutdatedBrowserPageComponent } from './pages/outdated-browser-page/outdated-browser-page.component';
import { RegisterOktaPageComponent } from './pages/register-okta-page/register-okta-page.component';
import { VerifyPageComponent } from './pages/verify-page/verify-page.component';
import { MergeDealersPageComponent } from './pages/merge-dealers-page/merge-dealers-page.component';
import { IsHelpdeskUserGuard } from './guards/is-helpdesk-user.guard';
import { ContactDetailsPageComponent } from './pages/contact-details-page/contact-details-page.component';
import { CompanyDataPageComponent } from './pages/company-data-page/company-data-page.component';
import { DiscountSheetPageComponent } from './pages/discount-sheet-page/discount-sheet-page.component';
import { IsInternalUserGuard } from './guards/is-internal-user.guard';
import { IsAuthorizedGuard } from './guards/is-authorized.guard';
import { IsCompanyGroupGuard } from './guards/is-company-group.guard';
import { PaymentSuccessComponent } from './components/basket-components/checkout/payment-success/payment-success.component';
import { UnmergeDealerPageComponent } from './pages/unmerge-dealer-page/unmerge-dealer-page.component';
import { IsUnverifiedGuard } from './guards/is-unverified.guard';
import { IsVerifiedGuard } from './guards/is-verified.guard';
import { DeletedBasketOverviewComponent } from './components/basket-components/deleted-basket-overview/deleted-basket-overview.component';
import { BarcodeComponent } from './pages/barcode/barcode.component';
import { MigrateAccountComponent } from './components/migratie-account/migrate-account.component';
import { GroupSelectHomepageComponent } from './components/group-select-homepage/group-select-homepage.component';
import { StoryblokPageComponent } from './pages/storyblok-page/storyblok-page.component';
import { PartnerPortalHomepageComponent } from './pages/partner-portal-homepage/partner-portal-homepage.component';
import { RegisterPartnerPageComponent } from './pages/partner-portal/register-partner-page/register-partner-page.component';
import { environment } from 'src/environments/environment';
import { HasSignedPreorderNdaGuard } from './guards/has-signed-preorder-nda.guard';
import { PartnerPortalPersonaldataPageComponent } from './pages/basket-page/partner-portal-personaldata-page/partner-portal-personaldata-page.component';
import { PartnerPortalBasketPersonaldataPageComponent } from './pages/partner-pages/basket-page/partner-portal-basket-personaldata-page/partner-portal-basket-personaldata-page.component';
import { ResetPasswordPartnerPortalComponent } from './pages/partner-portal/reset-password-partner-portal/reset-password-partner-portal.component';
import { ServicePortalGuard } from './guards/service-portal.guard';
import { DownloaderComponent } from './components/downloader/downloader.component';

// Overview of all the routes
const appRoutes: Routes = [
  {
    path: 'start',
    component: GroupSelectHomepageComponent,
  },
  {
    path: 'preview/:language/:cgc/:type/:id',
    loadChildren: () =>
      import('./modules/cms-preview/cms-preview.module').then(
        (m) => m.CmsPreviewModule
      ),
    canActivate: [IsAuthorizedGuard, IsVerifiedGuard],
  },


  { path: 'login/:redirectUrl', component: LoginPageComponent },
  { path: 'autologin/:key', component: LandingComponent },
  { path: 'autologin', component: LandingComponent },

  {
    path: ':cgc/settings',
    component: UserSettingsPageComponent,
    canActivate: [IsAuthorizedGuard],
  },
  {
    path: ':cgc/users',
    component: UsersPageComponent,
    canActivate: [IsAuthorizedGuard],
  },
  {
    path: ':cgc/leasing/articles',
    component: LeaseModelsComponent,
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/compare',
    component: CompareArticlesComponent,
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/search',
    loadChildren: () =>
      import('./modules/search/search.module').then((m) => m.SearchModule),
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/results',
    loadChildren: () =>
      import('./modules/search/search.module').then((m) => m.SearchModule),
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/search/:text',
    loadChildren: () =>
      import('./modules/search/search.module').then((m) => m.SearchModule),
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/results/:text',
    loadChildren: () =>
      import('./modules/search/search.module').then((m) => m.SearchModule),
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/search/:text/:filters',
    loadChildren: () =>
      import('./modules/search/search.module').then((m) => m.SearchModule),
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/results/:text/:filters',
    loadChildren: () =>
      import('./modules/search/search.module').then((m) => m.SearchModule),
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/articleDetails/:id',
    loadChildren: () =>
      import('./modules/search/search.module').then((m) => m.SearchModule),
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/articleDetails/:id/:articleCode',
    loadChildren: () =>
      import('./modules/search/search.module').then((m) => m.SearchModule),
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/articleDetails/:id/:articleCode/:framenumber',
    loadChildren: () =>
      import('./modules/search/search.module').then((m) => m.SearchModule),
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/articleModel/:id',
    loadChildren: () =>
      import('./modules/search/search.module').then((m) => m.SearchModule),
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/preorder/article/:articleCode',
    loadChildren: () =>
      import('./modules/preorder/preorder.module').then(
        (m) => m.PreorderModule
      ),
    data: { roles: ['Dealer'] },
    canActivate: [IsDealerGuard, HasSignedPreorderNdaGuard],
  },
  {
    path: ':cgc/article/:articleCode',
    loadChildren: () =>
      import('./modules/preorder/preorder.module').then(
        (m) => m.PreorderModule
      ),
    canActivate: [IsDealerGuard, HasSignedPreorderNdaGuard],
    data: {roles: ['Dealer']}
  },
  {
    path: ':cgc/baskets',
    component: BasketsPageComponent,
    data: { type: 'open', roles: ['partnerPortal', 'Dealer'] },
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/baskets/sent',
    component: BasketsPageComponent,
    canActivate: [IsDealerGuard],
    data: { type: 'sent', roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/baskets/archive',
    component: BasketsPageComponent,
    canActivate: [IsDealerGuard],
    data: { type: 'archive', roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/baskets/open',
    component: BasketsPageComponent,
    canActivate: [IsDealerGuard],
    data: { type: 'open', roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/basket',
    component: BasketPageComponent,
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/preorderBasket',
    component: PreorderBasketPageComponent,
    canActivate: [IsDealerGuard, HasSignedPreorderNdaGuard],
  },
  {
    path: ':cgc/basket/:id',
    component: BasketPageComponent,
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/preorderBasket/:type/:id',
    component: BasketPageComponent,
    canActivate: [IsDealerGuard, HasSignedPreorderNdaGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/checkout/:id',
    component: CheckoutPageComponent,
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/preorderCheckout/:id',
    component: CheckoutPageComponent,
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/preorderCheckout/:type/:id',
    component: CheckoutPageComponent,
    data: { roles: ['partnerPortal', 'Dealer'] },
    canActivate: [IsDealerGuard, HasSignedPreorderNdaGuard],
  },
  {
    path: ':cgc/preorderCheckout/:type/:id/:period',
    component: CheckoutPageComponent,
    canActivate: [IsDealerGuard, HasSignedPreorderNdaGuard],
    data: { roles: ['friendsAndFamily', 'Dealer'] },
  },
  {
    path: ':cgc/checkout/:id/payment-success',
    component: PaymentSuccessComponent,
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/financial/invoices/payment-success',
    component: PaymentSuccessComponent,
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/checkout/:id/:groupId',
    component: CheckoutPageComponent,
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/order/:id',
    component: SentBasketOverviewPageComponent,
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/baskets/archive/:id',
    component: DeletedBasketOverviewComponent,
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/imageDetails/:id',
    loadChildren: () =>
      import('./modules/image-bank/image-bank.module').then(
        (m) => m.ImageBankModule
      ),
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/paycond/:id',
    component: BasketPaymentConditionsComponent,
    canActivate: [IsDealerGuard, HasSignedPreorderNdaGuard],
  },
  {
    path: ':cgc/paycond/:id/:groupId',
    component: BasketPaymentConditionsComponent,
    canActivate: [IsDealerGuard, HasSignedPreorderNdaGuard],
  },
  {
    path: ':cgc/quickorder',
    loadChildren: () =>
      import('./modules/quickorder/quickorder.module').then(
        (m) => m.QuickorderModule
      ),
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/financial',
    component: FinancialPageComponent,
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/financial/:type',
    component: FinancialPageComponent,
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/financial/:type/:id',
    component: FinancialPageComponent,
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/financial/:dummy/:type',
    component: FinancialPageComponent,
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/news',
    component: NewsPageComponent,
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/news/:id',
    component: NewsPageComponent,
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/helpdesk',
    component: HelpdeskPageComponent,
    canActivate: [IsDealerGuard],
    data: { redirectUrl: '/' },
  },
  {
    path: ':cgc/helpdesk/:dealerid',
    component: HelpdeskPageComponent,
    canActivate: [IsDealerGuard],
    data: { redirectUrl: '/' },
  },
  {
    path: ':cgc/profiles',
    component: ProfilesPageComponent,
    canActivate: [IsAuthorizedGuard],
  },
  {
    path: ':cgc/favorites',
    redirectTo: '/:cgc/search#filter/&favorites=1',
  },
  {
    path: ':cgc/stocknotifications',
    redirectTo: '/:cgc/search#filter/&favorites=1&stocklist=1',
  },
  {
    path: ':cgc/preorder',
    redirectTo: '/:cgc/search#filter/&preorder=1',
    pathMatch: 'full',
  },
  {
    path: ':cgc/xl',
    redirectTo: '/:cgc/search#filter/&xl=1',
    pathMatch: 'full',
    data: { companyGroups: ['ANL'] },
  },
  {
    path: ':cgc/page',
    component: CompanyPageComponent,
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/notfound',
    component: ErrorPageComponent,
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/contactdetails',
    component: ContactDetailsPageComponent,
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/marketplace',
    loadChildren: () =>
      import('./modules/marketplace/marketplace.module').then(
        (m) => m.MarketplaceModule
      ),
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/pricelabels',
    loadChildren: () =>
      import('./modules/pricelabels/pricelabels.module').then(
        (m) => m.PricelabelsModule
      ),
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/recall',
    loadChildren: () =>
      import('./modules/recall/recall.module').then((m) => m.RecallModule),
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/leasing',
    loadChildren: () =>
      import('./modules/lease/lease.module').then((m) => m.LeaseModule),
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/kiosk',
    loadChildren: () =>
      import('./modules/kiosk/kiosk.module').then((m) => m.KioskModule),
    canActivate: [IsDealerGuard],
  },
  {
    path: 'loginOkta',
    loadChildren: () =>
      import('./modules/okta/okta.module').then((m) => m.OktaModule),
    canActivate: [IsUnverifiedGuard],
  },
  {
    path: ':cgc/turnoverstatistics',
    component: TurnoverStatisticsPageComponent,
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/companydata',
    component: CompanyDataPageComponent,
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/discountsheet/WS',
    component: DiscountSheetPageComponent,
    canActivate: [IsInternalUserGuard],
  },
  {
    path: ':cgc/discountsheet/WBP',
    component: DiscountSheetPageComponent,
    canActivate: [IsInternalUserGuard],
  },
  {
    path: ':cgc/imageBank',
    redirectTo: '/:cgc/search#filter/&imagebank=1',
    pathMatch: 'full',
  },
  {
    path: ':cgc/EBSC',
    loadChildren: () =>
      import('./modules/ebsc/ebsc.module').then((m) => m.EbscModule),
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/Brands',
    loadChildren: () =>
      import('./modules/brands/brands.module').then((m) => m.BrandsModule),
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/companyPage/:id',
    component: StoryblokPageComponent,
    canActivate: [IsDealerGuard],
    data: { roles: ['partnerPortal', 'Dealer'] },
  },
  {
    path: ':cgc/dropShipmentBasket',
    loadChildren: () =>
      import('./modules/dropshipment/dropshipment.module').then(
        (m) => m.DropshipmentModule
      ),
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/testrides',
    loadChildren: () =>
      import('./modules/testrides/testrides.module').then(
        (m) => m.TestridesModule
      ),
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/Warranty',
    component: WarrantyPageComponent,
    data: { type: 'overview' },
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/warranty',
    component: WarrantyPageComponent,
    data: { type: 'overview' },
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/warranty/download/:id',
    component: DownloaderComponent,
    canActivate: [IsAuthorizedGuard],
  },
  {
    path: ':cgc/aftersales',
    component: WarrantyPageComponent,
    data: { type: 'aftersales', companyGroups: ['LAP'] },
    canActivate: [IsDealerGuard, IsCompanyGroupGuard],
  },
  {
    path: ':cgc/warrantyOverview/active/:framenumber',
    component: WarrantyPageComponent,
    data: { type: 'overview', action: 'showActive' },
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/warrantyOverview/lease',
    component: WarrantyPageComponent,
    data: { type: 'overview', action: 'lease' },
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/warranty/:id',
    component: WarrantyPageComponent,
    data: { type: 'details' },
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/warranty/:id/:framenumber',
    component: WarrantyPageComponent,
    data: { type: 'details' },
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/warrantySendOverview/:id',
    component: WarrantyPageComponent,
    data: { type: 'sendDetails' },
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/lotteryRegistration',
    component: LotteryRegistrationPageComponent,
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/stock',
    component: NotImplementedPageComponent,
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/dealerdashboard',
    loadChildren: () =>
      import('./modules/dealer-dashboard/dealer-dashboard.module').then(
        (m) => m.DealerDashboardModule
      ),
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/dealeroverview',
    loadChildren: () =>
      import('./modules/dealer-overview-page/dealer-overview-page.module').then(
        (m) => m.DealerDashboardModule
      ),
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/LotteryRegistration',
    component: LotteryRegistrationPageComponent,
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/slideshows',
    component: SlideshowsOverviewPageComponent,
    canActivate: [IsDealerGuard],
  },
  {
    path: ':cgc/mergedealers',
    component: MergeDealersPageComponent,
    canActivate: [IsHelpdeskUserGuard],
  },
  {
    path: ':cgc/unmergedealer',
    component: UnmergeDealerPageComponent,
    canActivate: [IsHelpdeskUserGuard],
  },
  {
    path: ':cgc/outdatedBrowser',
    component: OutdatedBrowserPageComponent,
    canActivate: [IsDealerGuard],
  },
  {
    path: 'serviceportal',
    component: LandingComponent,
    canActivate: [IsDealerGuard, ServicePortalGuard],
  },
  {
    path: ':cgc/yearAgreement',
    component: YearAgreementComponent,
    data: { type: 'bikes', companyGroups: ['ANL'] },
    canActivate: [IsDealerGuard, IsCompanyGroupGuard],
  },
  {
    path: ':cgc/yearAgreement/parts',
    component: YearAgreementComponent,
    data: { type: 'pa', companyGroups: ['ANL'] },
    canActivate: [IsDealerGuard, IsCompanyGroupGuard],
  },
  {
    path: ':cgc/yearAgreement/p-and-a',
    component: YearAgreementComponent,
    data: { type: 'pa', companyGroups: ['ANL'] },
    canActivate: [IsDealerGuard, IsCompanyGroupGuard],
  },
  {
    path: ':cgc/yearAgreementDach',
    loadChildren: () =>
      import('./modules/year-agreement-dach/year-agreement-dach.module').then(
        (m) => m.YearAgreementDachModule
      ),
    data: { companyGroups: ['WG', 'GHO'] },
    canActivate: [IsDealerGuard, IsCompanyGroupGuard],
  },
  {
    path: ':cgc/yearAgreement/dk',
    loadChildren: () =>
      import('./modules/year-agreement-dk/year-agreement-dk.module').then(
        (m) => m.YearAgreementDkModule
      ),
    data: { companyGroups: ['ANL'] },
    canActivate: [IsDealerGuard, IsCompanyGroupGuard],
  },
  {
    path: ':cgc/yearAgreement/deKoga',
    loadChildren: () =>
      import(
        './modules/year-agreement-de-koga/year-agreement-de-koga.module'
      ).then((m) => m.YearAgreementDeKogaModule),
    data: { companyGroups: ['ANL'] },
    canActivate: [IsDealerGuard, IsCompanyGroupGuard],
  },
  {
    path: ':cgc/yearAgreement/chKoga',
    loadChildren: () =>
      import(
        './modules/year-agreement-ch-koga/year-agreement-ch-koga.module'
      ).then((m) => m.YearAgreementChKogaModule),
    data: { companyGroups: ['ANL'] },
    canActivate: [IsDealerGuard, IsCompanyGroupGuard],
  },
  {
    path: 'migrateaccount',
    component: MigrateAccountComponent,
  },
  {
    path: 'activateaccount',
    loadChildren: () =>
      import('./modules/account-activation/account-activation.module').then(
        (m) => m.AccountActivationModule
      ),
  },
  {
    path: 'verify',
    component: VerifyPageComponent,
  },
  {
    path: ':cgc/barcode',
    component: BarcodeComponent,
  },
  {
    path: ':cgc/juncker-plus',
    loadChildren: () =>
      import('./modules/juncker-plus/juncker-plus.module').then(
        (m) => m.JunckerPlusModule
      ),
  },
  {
    path: ':cgc/juncker-plus',
    loadChildren: () =>
      import('./modules/juncker-plus/juncker-plus.module').then(
        (m) => m.JunckerPlusModule
      ),
  },
  //Admin
  {
    path: ':cgc/admin',
    loadChildren: () =>
      import('./modules/admin-tools/admin-tools.module').then(
        (m) => m.AdminToolsModule
      ),
  },
  { path: '**', component: LandingComponent },
  { path: ':cgc/kiosk/**', redirectTo: '/:cgc/kiosk/home' },
];

if (environment.shopType === "partner-portal") {
  appRoutes.unshift({
    path: '',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  });
  appRoutes.unshift({
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  });
  appRoutes.unshift({
    path: 'Logout',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  });
  appRoutes.unshift({
    path: ':cgc/home',
    component: PartnerPortalHomepageComponent,
  });
  appRoutes.unshift({
    path: ':cgc/shipping/:id',
    component: PartnerPortalBasketPersonaldataPageComponent,
  });
  appRoutes.unshift({
    path: ':cgc/personaldata',
    component: PartnerPortalPersonaldataPageComponent,
  });
  appRoutes.unshift({
    path: 'resetpassword',
    component: ResetPasswordPartnerPortalComponent,
  });
} else {
  appRoutes.unshift({ path: 'login', component: LoginPageComponent });
  appRoutes.unshift({
    path: ':cgc/home',
    loadChildren: () =>
      import('./modules/home-page/home-page.module').then(
        (m) => m.HomePageModule
      ),
    canActivate: [IsAuthorizedGuard, IsVerifiedGuard, IsDealerGuard],
    data: { roles: ['Dealer', 'Helpdesk'] },
    pathMatch: 'full',
  });
  appRoutes.unshift(  { path: 'Logout', component: LoginPageComponent })
  appRoutes.unshift(  { path: 'logout', component: LoginPageComponent })
  appRoutes.unshift({
    path: 'register',
    component: RegisterOktaPageComponent,
    canActivate: [IsUnverifiedGuard],
  })

  appRoutes.unshift({
      path: 'resetpassword',
      component: LoginPageComponent,
      data: { type: 'resetPassword' },
  })

}

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
