import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DealerService } from "src/app/services/dealer.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { HelperService } from "src/app/services/helper.service";
import { DealerStockLink } from "src/app/interfaces/dealer";
import { ConfirmDialogComponent } from "../../modal-components/confirm-dialog/confirm-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { DealerStockTokenContainerComponent } from "../dealer-stock-token-container/dealer-stock-token-container.component";
import { saveAs } from "file-saver";
import { AlertService } from "src/app/services/alert.service";
import { ApplicationService } from "src/app/services/application.service";
@Component({
  selector: "app-dealer-settings-container",
  templateUrl: "./dealer-settings-container.component.html",
  styleUrls: ["./dealer-settings-container.component.scss"],
})
export class DealerSettingsContainerComponent implements OnInit {
  logosrc: string;
  dealerStockLinkSettings: DealerStockLink = null;
  loading: boolean;

  // logo: string;
  constructor(
    private modalService: NgbModal,
    private dealerService: DealerService,
    private helperService: HelperService,
    private translateService: TranslateService,
    private alertService: AlertService,
    private applicationService: ApplicationService,
  ) {}

  ngOnInit() {
    this.getLogo();
    this.getDealerStockLink();
  }

  getLogo() {
    this.dealerService.getLogo().subscribe((apiResponse: ApiResponse) => {
      if (this.helperService.checkResponse(apiResponse)) {
        this.logosrc = apiResponse.result;
      }
    });
  }

  uploadLogo(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.dealerService
          .uploadLogo(String(reader.result))
          .subscribe((apiResponse: ApiResponse) => {
            if (apiResponse.success) {
              this.logosrc = apiResponse.result;
            }
          });
      };
    }
  }

  getDealerStockLink() {
    this.loading = true;
    this.applicationService.showLoader(true);
    this.dealerService
      .dealerStockLink()
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.dealerStockLinkSettings = apiResponse.result;
        }
        this.applicationService.hideLoader();
        this.loading = false;
      });
  }

  createDealerStockLinkToken() {
    if (!this.dealerStockLinkSettings.has_api_key) {
      this.showTokenModal();
    } else {
        const modalRef = this.modalService.open(ConfirmDialogComponent, {
          centered: true,
          size: "sm",
          windowClass: "confirmDialog",
        });
      modalRef.componentInstance.isConfirm = true;
      modalRef.componentInstance.title = this.translateService.instant("NOTE");
      modalRef.componentInstance.body = this.translateService.instant("DEALER_LINK_TOKEN_EXISTS_WARNING");
       modalRef.componentInstance.confirmClicked.subscribe(() => {
         this.showTokenModal();
       });
    }
  }

  showTokenModal() {
    this.dealerService
      .createDealerStockLinkToken()
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          const modalRef = this.modalService.open(ConfirmDialogComponent, {
            centered: true,
            size: "lg",
            windowClass: "confirmDialog",
          });
          modalRef.componentInstance.setContent(
            DealerStockTokenContainerComponent,
            "tokenData",
            apiResponse.result
          );
          modalRef.componentInstance.title =
            this.translateService.instant("TOKEN");
        }
      });
  }

  downloadStockData() {
    this.dealerService.getDealerStockDate().subscribe((blob: Blob) => {
      this.generateUrl(blob);
    });
  }

  activate() {
    this.dealerService.activateDealerStock().subscribe((apiResponse: ApiResponse) => {
      if (apiResponse && apiResponse.success) {
        this.alertService.showInfoNotification(this.translateService.instant("DEALER_LINK_ACTIVE"));
        this.dealerStockLinkSettings.activated = true;
        this.dealerStockLinkSettings.can_be_activated = false;
      } else {
        apiResponse.result =
          this.translateService.instant("ERROR");
        this.alertService.showErrorNotification(apiResponse.result);
      }
    });
  }

  // Generate pdf
  generateUrl(blob) {
    const file = new Blob([blob], { type: "text/plain" });
    saveAs(file, `stock.csv`);
  }
}
