export const environment = {
  shopType: window['ACC_SHOPTYPE'] ? window['ACC_SHOPTYPE'] : (process.env["ACC_SHOPTYPE"] ? process.env["ACC_SHOPTYPE"] : 'b2b'),
  storeName: window['ACC_STORENAME'] ? window['ACC_STORENAME'] : (process.env["ACC_STORENAME"] ? process.env["ACC_STORENAME"] : 'raleigh'),
  production: false,
  development: false,
  apiendpoint: "https://api-gateway.web-t.accell.cloud/",
  socketapiendpoint: "https://hubs.web-t.accell.cloud/notify/",
  publicapiendpoint: "https://public.web-t.accell.cloud/",
  classicUrl: "https://classic-test.accentry.com/login/connect?data=",
  oktaUrl: "https://dev-687002.oktapreview.com/login/",
  name: "test",
  hostname: "https://alpha.accentry.com/",
  storyblokPreviewToken: "oMQHuawFZE3OUaMuz0mBtgtt",
  blobStorageUrl: "https://agstoraccentryt.blob.core.windows.net/",
  storyblokAccentryPreviewToken: "9AdRnSHRluj5x2mujLUoGiXdn6slRJpB48Hpx5a5IaM=",
  previewUser: {
    username: "preview_cms",
    password: "Cms1234!",
  },
  imageBankAdmins: ["databeheer@accell.nl"],
};
